/* HERO */

.is-hero {
  padding-top: 4rem !important;
}

.is-hero .hero-head {
  padding: 0 1.5rem;
}

.is-hero .hero-body {
  display: block !important;
}

/* TILES */

.is-tiles {
  margin-top: 12px !important;
}

/* LOAD */

.is-loading {
  border: none !important;
  font-size: 5rem !important;
  width: 100% !important;
}

/* MODAL */

.is-modal .modal-background {
  opacity: 0.75;
}

.is-modal .card {
  height: 100% !important;
  overflow: scroll;
}

.is-modal .delete {
  background: none;
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
}

.is-modal .delete::after,
.is-modal .delete::before {
  background: #000 !important;
}

/* FOOTER */

.foot-links .foot-link {
  border: none !important;
}
